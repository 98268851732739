.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.css-m69qwo-MuiStack-root {
  align-items: center;
}

/*座席ボタンテキスト要素*/
.seat-button-text {
  text-align: super;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-transform: none;
}

/*座席ボタン名前配置*/
.seat-button-name-layout {
  width: 100%;
  height: 50%;
  position: absolute;
  overflow: hidden;
  top: 3%;
  left: 0;
  right: 0;
  margin: auto;
}

/*座席ボタンラベル配置*/
.seat-button-label-layout {
  width: 80%;
  height: 40%;
  position: absolute;
  top: 55%;
  left: 0;
  right: 0;
  margin: auto;
}
